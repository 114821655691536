require("./scss/home.scss");
require("slick-carousel/slick/slick.css");
require("slick-carousel/slick/slick-theme.css");
require("slick-carousel");



// Slider principal single
$('.slider').slick({
    fade: true,
    // cssEase: 'linear',
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 3200,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows:false,
    
  });

  // slick produtos

  $(document).ready(function(){
    $('.my-slider-two').slick({
      slidesToShow: 5,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 1000,
      speed: 10000,
      dots: true,
      arrows: false,
      cssEase: 'linear',
      infinite: true,
      // prevArrow: '<span class="slick-prev">→</span>',
      // nextArrow: '<span class="slick-next">→</span>',
      responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    }
  ]
    });
   
  });

  $(document).ready(function(){
    $('.my-slider').slick({
      slidesToShow: 5,
      autoplay: true,
      slidesToScroll: 3,
      autoplaySpeed: 1000,
      speed: 12000,
      dots: true,
      arrows: false,
      cssEase: 'linear',
      infinite: true,
      // prevArrow: '<span class="slick-prev">→</span>',
      // nextArrow: '<span class="slick-next">→</span>',
      responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    }
  ]
    });
   
  });
